class MrFullscreenToggle extends HTMLElement {
	private fullscreenChangeHandler = ( event: Event ) => {
		if ( event.target !== this ) {
			return;
		}

		const allImagesWithValidSrcSets = Array.from( this.querySelectorAll<HTMLImageElement>( 'img[srcset]' ) );
		const allImagesWithDisabledSrcSets = Array.from( this.querySelectorAll<HTMLImageElement>( 'img[data-disabled-srcset]' ) );

		allImagesWithValidSrcSets.forEach( ( image ) => {
			image.setAttribute( 'data-disabled-srcset', image.getAttribute( 'srcset' ) ?? '' );
			image.setAttribute( 'data-disabled-sizes', image.getAttribute( 'sizes' ) ?? '' );

			const u1 = new URL( image.currentSrc );
			u1.searchParams.delete( 'w' );
			u1.searchParams.delete( 'h' );
			u1.searchParams.set( 'w', window.innerWidth.toString() );

			const u2 = new URL( image.currentSrc );
			u2.searchParams.delete( 'w' );
			u2.searchParams.delete( 'h' );
			u2.searchParams.set( 'w', ( window.innerWidth * 2 ).toString() );

			image.setAttribute( 'srcset', `${u2.toString()} 2x, ${u2.toString()}` );
			image.removeAttribute( 'sizes' );
		} );

		allImagesWithDisabledSrcSets.forEach( ( image ) => {
			image.setAttribute( 'srcset', image.getAttribute( 'data-disabled-srcset' ) ?? '' );
			image.setAttribute( 'sizes', image.getAttribute( 'data-disabled-sizes' ) ?? '' );

			image.removeAttribute( 'data-disabled-srcset' );
			image.removeAttribute( 'data-disabled-sizes' );
		} );
	};

	private clickHandler = ( event: Event ) => {
		const target = event.target;
		if ( !target ) return;

		if ( target === this && document.fullscreenElement === this ) {
			document.exitFullscreen?.();

			return;
		}

		if ( ( target instanceof HTMLElement ) && target.matches( 'img' ) && document.fullscreenElement === this ) {
			document.exitFullscreen?.();

			return;
		}
	};

	connectedCallback() {
		this.addEventListener( 'fullscreenchange', this.fullscreenChangeHandler );
		this.addEventListener( 'click', this.clickHandler );

		requestAnimationFrame( () => {
			if ( 'requestFullscreen' in document.documentElement && 'fullscreenEnabled' in document && document.fullscreenEnabled ) {
				this.parentElement?.setAttribute( 'data-request-fullscreen-supported', '' );
			}

			const hasWideContent = Boolean( this.querySelector( '.mr-table-scroll-container' ) );

			if ( hasWideContent ) {
				this.parentElement?.setAttribute( 'data-has-wide-content', '' );
			}
		} );
	}

	disconnectedCallback() {
		this.removeEventListener( 'fullscreenchange', this.fullscreenChangeHandler );
		this.removeEventListener( 'click', this.clickHandler );
	}

	updateState( directive: string ) {
		switch ( directive ) {
			case 'request-fullscreen':
				switch ( Boolean( document.fullscreenElement ) ) {
					case false:
						this.requestFullscreen?.().catch( ( err ) => {
							console.warn( err );
						} );

						break;

					default:
						return; // Already presenting an element in full screen.
				}
				break;

			default:
				return; // Only "request-fullscreen" is supported
		}
	}
}

window.customElements.define( 'mr-fullscreen-toggle', MrFullscreenToggle );
